import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";

import Head from "../components/pages/head";
import MarketingLayout from "../components/pages/marketing-layout";
import { mainHeader } from "../styles/typography";
import variables from "../styles/variables";
import i18n from "../helpers/i18n";
import AccentButton from "../components/common/accent-button";
import userService from "../services/user-service";
import useAppStores from "../context/app-context";
import { getLocalStorage } from "../helpers/local-storage";
import { setAuthorization } from "../services/api";
import HelpModal from "../components/help/help-modal";

const layout = css`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  height: 100%;
`;

const title = css`
  ${mainHeader};
  text-align: center;
  margin: ${variables.spaceLarge} 0 ${variables.spaceBase};
`;

const contentWrapper = css`
  padding: ${variables.spaceLarge} ${variables.spaceBase};

  @media (min-width: ${variables.laptopMaxWidth}) {
    padding: ${variables.spaceBase} 0;
  }
`;

const errorMessageSection = css`
  margin-bottom: ${variables.spaceBase};
`;

const content = css`
  margin: 0 auto;
  max-width: ${variables.mobileMidWidth};
  text-align: center;
`;

const Page = () => {
  const { userStore } = useAppStores();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      const jwtToken = getLocalStorage("jwtToken");
      if (jwtToken) {
        setAuthorization(jwtToken);
        const res = await userService.fetchMe();
        userStore.set(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openHelp = async () => {
    setIsHelpModalOpen(true);
  };

  return (
    <>
      <Head
        noIndex
        title={i18n.t("error")}
        description={i18n.t("errorOccurred")}
      />
      <MarketingLayout>
        <div css={layout}>
          <div>
            <h1 css={title}>{i18n.t("error")} 404</h1>

            <div css={contentWrapper}>
              <div css={content}>
                <div css={errorMessageSection}>
                  <p>{i18n.t("pageDoesNotExist")}</p>
                </div>
                <AccentButton onClick={openHelp}>
                  {i18n.t("contactSupport")}
                </AccentButton>
              </div>
            </div>
          </div>
        </div>
      </MarketingLayout>
      {isHelpModalOpen && (
        <HelpModal
          isOpen={isHelpModalOpen}
          onRequestClose={() => setIsHelpModalOpen(false)}
          onSuccess={() => setIsHelpModalOpen(false)}
        />
      )}
    </>
  );
};

export default Page;
